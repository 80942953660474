// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.bg-gray {
    background-color: #363535;
}

.mini-block {
    margin-right: 5px;
    background-color: rgb(24, 25, 27);
    padding: 5px;
    border-radius: 10px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/AnimePage.css"],"names":[],"mappings":";AACA;IACI,yBAAyB;AAC7B;;AAEA;IACI,iBAAiB;IACjB,iCAAiC;IACjC,YAAY;IACZ,mBAAmB;AACvB","sourcesContent":["\n.bg-gray {\n    background-color: #363535;\n}\n\n.mini-block {\n    margin-right: 5px;\n    background-color: rgb(24, 25, 27);\n    padding: 5px;\n    border-radius: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
