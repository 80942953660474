// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-control::placeholder {
    color: white;
    font-style: italic;
}

.form-control {
    background-color: rgb(33, 37, 41, 1);
}`, "",{"version":3,"sources":["webpack://./src/Components/Search/Search.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,oCAAoC;AACxC","sourcesContent":[".form-control::placeholder {\n    color: white;\n    font-style: italic;\n}\n\n.form-control {\n    background-color: rgb(33, 37, 41, 1);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
