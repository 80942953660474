// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.anime_card:hover{
  transform: scale(1.05);
  background-color: rgba(33, 37, 41, 1);
  z-index: 1000;
}

.anime_card {
  transition: 0.2s;
}`, "",{"version":3,"sources":["webpack://./src/Components/Main/AnimeItem.css"],"names":[],"mappings":";AACA;EACE,sBAAsB;EACtB,qCAAqC;EACrC,aAAa;AACf;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["\n.anime_card:hover{\n  transform: scale(1.05);\n  background-color: rgba(33, 37, 41, 1);\n  z-index: 1000;\n}\n\n.anime_card {\n  transition: 0.2s;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
