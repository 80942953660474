// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `nav {
    text-transform: uppercase;
}

.nav-link {
    transition: .2s;
    margin: 10px;
}
.nav-link:hover {
    background-color: rgb(25, 135, 84, 1);
}

.sub-menu-link:before {
    content: '';
    position: absolute;
    display: block;
    margin: auto;
    height: 0;
    width: 0;
    left: 0;
    right: 0;
    bottom: -1px;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 8px solid var(--main-red)
}

.dropdown:hover>.dropdown-menu {
    display: block;
}`, "",{"version":3,"sources":["webpack://./src/Components/Header/Header.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;;AAEA;IACI,eAAe;IACf,YAAY;AAChB;AACA;IACI,qCAAqC;AACzC;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,cAAc;IACd,YAAY;IACZ,SAAS;IACT,QAAQ;IACR,OAAO;IACP,QAAQ;IACR,YAAY;IACZ,kCAAkC;IAClC,mCAAmC;IACnC;AACJ;;AAEA;IACI,cAAc;AAClB","sourcesContent":["nav {\n    text-transform: uppercase;\n}\n\n.nav-link {\n    transition: .2s;\n    margin: 10px;\n}\n.nav-link:hover {\n    background-color: rgb(25, 135, 84, 1);\n}\n\n.sub-menu-link:before {\n    content: '';\n    position: absolute;\n    display: block;\n    margin: auto;\n    height: 0;\n    width: 0;\n    left: 0;\n    right: 0;\n    bottom: -1px;\n    border-left: 7px solid transparent;\n    border-right: 7px solid transparent;\n    border-bottom: 8px solid var(--main-red)\n}\n\n.dropdown:hover>.dropdown-menu {\n    display: block;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
