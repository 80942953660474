import React from 'react';
import Image from 'react-bootstrap/Image';

const Authorization = () => {
    return (
        <div>
            <Image src="/top.png"></Image>
        </div>
      );
};

export default Authorization;